<template>
  <div class="stack-container">
    <p class="stack-title" :class="type">
      {{ capitalizedTitle }}
    </p>

    <div class="skill-container">
      <SkillComponent
        v-for="(skill, idx) in stack"
        :key="idx"
        :title="skill"
        :type="type"
        :clickable="true"
        :active="skillFilters.includes(skill)"
        @click="$emit('skillClick', skill)"
      />
    </div>
  </div>
</template>

<script>
import "./SkillStackComponent.scss";
import SkillComponent from "@/components/skill/SkillComponent.vue";

export default {
  name: "SkillStackComponent",

  components: { SkillComponent },

  props: {
    type: {
      type: String,
      required: true,
    },

    stack: {
      type: Array,
      required: true,
    },

    skillFilters: {
      type: Array,
      required: true,
    },
  },

  computed: {
    capitalizedTitle() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    },
  },
};
</script>