<template>
  <PageComponent>
    <SectionComponent id="about">
      <template v-slot:content>
        <p
          v-for="(paragraph, index) in aboutParagraphs"
          :key="index"
          v-html="paragraph"
        ></p>
      </template>
    </SectionComponent>

    <SectionComponent
      id="skills"
      title="Skills"
      subtitle="Click a skill to filter experiences by it."
    >
      <template v-slot:content>
        <div class="skills-container">
          <div class="skill-stacks-container">
            <SkillStackComponent
              type="backend"
              :stack="backendStack"
              :skillFilters="skillFilters"
              @skillClick="toggleFilterBySkill"
            />
            <SkillStackComponent
              type="frontend"
              :stack="frontendStack"
              :skillFilters="skillFilters"
              @skillClick="toggleFilterBySkill"
            />
            <SkillStackComponent
              type="other"
              :stack="otherStack"
              :skillFilters="skillFilters"
              @skillClick="toggleFilterBySkill"
            />
          </div>

          <button
            class="filter-reset-button"
            :class="canResetFilter ? '' : 'disable'"
            :disabled="!canResetFilter"
            @click="resetFilter"
          >
            Reset filter
          </button>
        </div>
      </template>
    </SectionComponent>

    <SectionComponent id="experience" title="Experience">
      <template v-slot:content>
        <TimelineComponent
          :events="careerExperienceEvents"
          :skillFilters="skillFilters"
        />
      </template>
    </SectionComponent>

    <SectionComponent id="education" title="Education">
      <template v-slot:content>
        <TimelineComponent :events="careerEducationEvents" />
      </template>
    </SectionComponent>
  </PageComponent>
</template>

<script>
import "./HomePage.scss";
import PageComponent from "@/components/pages/PageComponent.vue";
import SectionComponent from "@/components/section/SectionComponent.vue";
import SkillStackComponent from "@/components/skill-stack/SkillStackComponent.vue";
import TimelineComponent from "@/components/timeline/TimelineComponent.vue";

export default {
  name: "HomePage",

  components: {
    PageComponent,
    SectionComponent,
    SkillStackComponent,
    TimelineComponent,
  },

  methods: {
    toggleFilterBySkill(skill) {
      if (this.skillFilters.includes(skill)) {
        this.skillFilters = this.skillFilters.filter(
          (filteredSkill) => filteredSkill != skill
        );
      } else {
        this.skillFilters.push(skill);
      }
    },

    resetFilter() {
      this.skillFilters = [];
    },
  },

  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },

  computed: {
    backendStack() {
      return [
        ...new Set(
          this.careerExperienceEvents
            .flatMap((event) => event.stack)
            .filter((skill) => skill?.type === "backend")
            .map((skill) => skill.name)
        ),
      ];
    },

    frontendStack() {
      return [
        ...new Set(
          this.careerExperienceEvents
            .flatMap((event) => event.stack)
            .filter((skill) => skill?.type === "frontend")
            .map((skill) => skill.name)
        ),
      ];
    },

    otherStack() {
      return [
        ...new Set(
          this.careerExperienceEvents
            .flatMap((event) => event.stack)
            .filter((skill) => skill?.type === "other")
            .map((skill) => skill.name)
        ),
      ];
    },

    canResetFilter() {
      return this.skillFilters.length != 0;
    },
  },

  data() {
    return {
      skillFilters: [],
      aboutParagraphs: [
        "I'm a full-stack software engineer with a Master's degree in Computer Science and expertise in backend engineering and API design. Having developed for various high-impact companies, ranging from <a href='https://www.systematic.com/' target='_blank'>a NATO partner</a> to <a href='https://www.microsoft.com/' target='_blank'>a major big tech corporation</a>, I've gained invaluable experience in software engineering, leadership and stakeholder management. My passion truly lies in building and delivering impactful solutions that empower individuals and organizations, and I do so with a cup-half-full attitude!",
        "Outside of building cool projects in my work and private time, I also enjoy following sports and esports, playing all sorts of games, hiking, and planning my next adventures.",
      ],
      careerExperienceEvents: [
        {
          title: "Senior Software Developer (Scrum Master)",
          summary: [
            "Scrum Master for an application security team of 11 developers and testers",
            "Modernizing the security layer of a large Java/Scala/C# application to be OAuth 2.0-, OIDC-, and JWT-compliant",
            "Implementing a cluster environment using Kubernetes, Istio, Helm and Docker",
          ],
          description:
            "Developing the C4ISR solution <b>SitaWare Headquarters</b> as part of the application security team. The team deals with modernizing the security layer by migrating to an OAuth 2.0-, OIDC- and JWT-compliant solution, utilizing Keycloak for identity- and access management. Our responsibilities also includes extending the cluster capabilities of the solution, utilizing Kubernetes, Istio, Helm and Docker to establish a robust, secure and performant cluster environment.",
          institution: "Systematic",
          date: "Oct 2023 - now",
          logo_path: "systematic.png",
          stack: [
            { name: "Java", type: "backend" },
            { name: "Scala", type: "backend" },
            { name: "C#", type: "backend" },
            { name: "PowerShell", type: "backend" },
            { name: "Bash", type: "backend" },
            { name: "Docker", type: "other" },
            { name: "Kubernetes", type: "other" },
            { name: "Keycloak", type: "other" },
            { name: "TeamCity", type: "other" },
            { name: "Microservices", type: "other" },
            { name: "REST", type: "other" },
            { name: "GIT", type: "other" },
          ],
        },
        {
          title: "Software Engineer Student Worker",
          summary: [
            "Designed, developed, and delivered multiple requested features within a cross-functional environment",
            "Delivered the highest number of work items on my team, three times more than the second-highest",
          ],
          description:
            "Developed the acclaimed ERP solution <b>Dynamics 365 Business Central</b> as part of the application-foundation team. The team was responsible for the horizontal domain-logic, such as e-mail communication, data import and export, permissions, file-handling, and more. I contributed to the team with the highest number of bug-fixes while developing partner-requested features and onboarding, developing and supervising an intern.",
          institution: "Microsoft",
          date: "Apr 2022 - Aug 2023",
          logo_path: "microsoft.png",
          stack: [
            { name: "AL", type: "backend" },
            { name: "SQL", type: "backend" },
            { name: "GIT", type: "other" },
          ],
        },
        {
          title: "Software Developer",
          summary: [
            "Tackled steep learning-curves developing solutions for clients of all sizes in various business domains",
            "Designed and developed complex systems and RESTful APIs using Java, Python, and Vue.js, usually with SQL databases",
            "Developed optimized solutions, such as when extending a Java eFX pricing engine to expand engine capabilities in a low-latency environment",
            "Gained experience with microservices, using technologies such as Docker, message brokers, and ELK",
            "Was offered contract extensions in all my projects",
          ],
          description:
            "Consultant for a number of prominet clients such as Danske Bank, Telia, Chr. Hansen, and SKAT. I focused mainly on backend development in various programming languages, spanning a wide range of tech stacks from small-scale start-up solutions to large-scale, latency-efficient environments. I thrived in tackling steep learning curves and jumping straight into dynamic environments - this enabled me to take control of projects behind on deadlines and deliver high-quality solutions.",
          secondaryTitle: "Projects As Consultant",
          secondaryDescription:
            "<ul><li><b>Danske Bank</b>: Low-latency eFX pricing engine for calculating and communicating exchange rates.</li><li><b>Telia (Merkle)</b>: Self-service with new design and solution componentization.</li><li><b>Silvi.ai</b>: AI-powered solution for automating meta-analysis within the field of medicine.</li><li><b>SKAT (DXC)</b>: Intercountry VAT communication system with ability to track VAT in call-off-stock arrangements.</li></ul>",
          institution: "IT Minds",
          date: "Feb 2020 - Mar 2022",
          logo_path: "itminds.png",
          stack: [
            { name: "Java", type: "backend" },
            { name: "Scala", type: "backend" },
            { name: "Python", type: "backend" },
            { name: "SQL", type: "backend" },
            { name: "Vue", type: "frontend" },
            { name: "TypeScript", type: "frontend" },
            { name: "JavaScript", type: "frontend" },
            { name: "HTML", type: "frontend" },
            { name: "CSS", type: "frontend" },
            { name: "Docker", type: "other" },
            { name: "RabbitMQ", type: "other" },
            { name: "Kafka", type: "other" },
            { name: "Microservices", type: "other" },
            { name: "REST", type: "other" },
            { name: "GIT", type: "other" },
          ],
        },
        {
          title: "IT Student",
          summary: ["Developed a SQL database restoration tool in C#"],
          description:
            "Developed a standalone database restoration tool for internal use. The solution utilized WinForms framework to provide a UI for developers when restoring databases from stored .bak files.",
          institution: "Danske Bank",
          date: "May 2019 - Jul 2019",
          logo_path: "danskebank.png",
          stack: [
            { name: "C#", type: "backend" },
            { name: "SQL", type: "backend" },
            { name: "GIT", type: "other" },
          ],
        },
        {
          title: "Conscript (Conscript Squad Leader)",
          summary: ["Assistant Squad Leader", "Grade: A2"],
          description:
            "Voluntary conscript with the assigned role of assistant squad leader in charge of managing and leading a squad of 11 conscripts. I focused on leading and motivating the team while facilitating communication between the team and leadership.",
          institution: "Danish Defence",
          date: "Aug 2014 - Nov 2014",
          logo_path: "forsvaret.png",
        },
      ],
      careerEducationEvents: [
        {
          title: "MSc in Computer Science",
          summary: [
            "Software Engineering track",
            "Explored advanced topics such as Software Engineering and Architecture, Blockchain, Machine Learning, and Human-Centered Design",
            "Gained proficiency in Project Management, Entrepeneurship, and Innovation",
            "Wrote Master's thesis in blockchain and process",
            "Graduation was followed by a PHD offer within process mining",
          ],
          description:
            "Expanded my knowledge in algorithms, advanced programming, and IT security. Studying on the software engineering track, I delved into intriguing areas such as blockchain technology, software architecture. Additionally, I researched machine learning applications and implications, and acquired valuable skills in project management, innovation, and entrepreneurship. I wrote my thesis on implementing a blockchain with native support for DCR Graph smart contracts, which received the maximum grade and was followed by a Ph. D. offer.",
          institution: "University of Copenhagen",
          date: "Sep 2021 - Jun 2023",
          logo_path: "ucph.png",
        },
        {
          title: "Scrum Course Certificate",
          description:
            "Learned about Scrum and team management in agile environments. Throughout the duration of the course, I actively participated in various team-based exercises and tackled diverse case studies, improving my skills in effectively organizing and guiding teams towards accomplishing shared objectives.",
          institution: "Systematic",
          date: "May 2017",
          logo_path: "systematic.png",
        },
        {
          title: "BSc in Computer Science",
          description:
            "Gained extensive knowledge of programming and advanced computer science theory. I wrote my thesis on analyzing the efficacy of the DoS attack Slowloris and its capabilities in various environments, and how Apache HTTP Server as a webserver implements security techniques to mitigate such attacks.",
          institution: "University of Copenhagen",
          date: "Sep 2016 - Feb 2020",
          logo_path: "ucph.png",
        },
      ],
    };
  },
};
</script>