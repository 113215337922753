<template>
  <div class="timeline" ref="timeline">
    <div
      class="entry"
      v-for="(event, idx) in events"
      :key="idx"
      :class="expandEvent(event) ? '' : 'collapse'"
    >
      <div class="marker">
        <img :src="require(`@/assets/logos/${event.logo_path}`)" alt="" />
      </div>

      <div class="card">
        <div class="card-content">
          <div class="card-top">
            <div class="card-details">
              <span class="date">{{ event.date }}</span>
              <span class="institution-title">{{ event.title }}</span>
              <span class="institution-name">{{ event.institution }}</span>
            </div>
          </div>
          <ul v-if="event.summary" class="card-summary">
            <li v-for="(detail, detailIdx) in event.summary" :key="detailIdx">
              {{ detail }}
            </li>
          </ul>
          <p class="card-description" v-html="event.description"></p>
        </div>
        <div class="stack">
          <SkillComponent
            v-for="(skill, idx) in event.stack"
            :key="idx"
            :title="skill.name"
            :type="skill.type"
            :active="skillFilters.includes(skill.name)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./TimelineComponent.scss";
import SkillComponent from "@/components/skill/SkillComponent.vue";

export default {
  name: "TimelineComponent",

  components: {
    SkillComponent,
  },

  props: {
    events: {
      type: Array,
      required: true,
    },

    skillFilters: {
      type: Array,
      required: false,
    },
  },

  mounted() {
    const timeline = this.$refs.timeline;
    if (timeline) {
      const entryWrappers = timeline.getElementsByClassName("card");
      for (let i = 0; i < entryWrappers.length; i++) {
        setTimeout(() => {
          entryWrappers[i].style.opacity = 1;
        }, i * 250);
      }
    }
  },

  methods: {
    expandEvent(event) {
      if (this.skillFilters == null || this.skillFilters.length === 0) {
        return true;
      }

      if (event.stack) {
        return event.stack.some((skill) =>
          this.skillFilters.includes(skill.name)
        );
      }

      return false;
    },
  },
};
</script>